import React from 'react'

import {
  TextField,
  TextFieldProps,
  List,
  makeStyles,
  createStyles,
  Theme,
  Fade
} from '@material-ui/core'
import '@material-ui/core/MenuItem/'

export interface DropdownWrapperProps {
  children: React.ReactNode
}

export function DropdownWrapper(props: DropdownWrapperProps) {
  return <div style={{ position: 'relative' }}>{props.children}</div>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
      position: 'absolute',
      overflow: 'overlay',
      width: 'auto',
      padding: 0,
      '&>*>*': {
        pointerEvents: 'none !important'
      },
      '&::-webkit-scrollbar': {
        width: 6,
        background: 'transparent'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        borderRadius: 3,
        background: 'transparent'
      }
    }
  })
)

export type Content = string

export interface DropdownProps extends Omit<TextFieldProps, 'onChange'> {
  children: React.ReactNode
  onChange?: (value: string) => void
  onChangeDone?: (selection: Content, error: boolean) => void
  defaultValue: string
  maxHeight: number
  maxWidth: number | 'none' | 'auto'
  notEmpty: boolean
  allowCustomInputs: boolean
  overwriteSearchOnError: boolean
  errorOnEmpty: boolean
  errorAtStartup: boolean
  errorWhileFocused: boolean
}

Dropdown.defaultProps = {
  maxHeight: 300,
  maxWidth: 'auto',
  defaultValue: '',
  notEmpty: false,
  allowCustomInputs: false,
  overwriteSearchOnError: false,
  errorOnEmpty: false,
  errorAtStartup: false,
  errorWhileFocused: false
}

export default function Dropdown(props: DropdownProps) {
  const {
    children,

    onChange,
    onChangeDone,

    value,
    defaultValue,

    maxHeight,
    maxWidth,

    notEmpty,
    overwriteSearchOnError,

    errorAtStartup,
    allowCustomInputs,
    errorOnEmpty,
    errorWhileFocused,

    className,
    style,
    ...rest
  } = props

  const isError = (open: boolean, searched: string = search) => {
    if (open)
      return (
        errorWhileFocused &&
        ((!React.Children.toArray(children).some((item: React.ReactElement) =>
          item.props.value?.toLowerCase().includes(searched.toLowerCase())
        ) &&
          !allowCustomInputs) ||
          (errorOnEmpty && !searched.length))
      )
    else
      return (
        (!!searched.length &&
          !React.Children.toArray(children).some(
            (item: React.ReactElement) => item.props.value === searched
          ) &&
          !allowCustomInputs) ||
        (errorOnEmpty && !searched.length)
      )
  }

  const recalculateRenderedHeight = () => {
    let inputSize = input.current?.getBoundingClientRect() as DOMRect

    setRenderedHeight(
      Math.min(
        window.innerHeight - inputSize.y - inputSize.height - 50,
        maxHeight
      )
    )
  }

  const callbackDone = (searched: string) => {
    let error = isError(false, searched)

    if (overwriteSearchOnError && error) overwriteSearchValid()
    else {
      onChange?.(searched)
      onChangeDone?.(searched, error)
    }
  }

  const overwriteSearchValid = () => {
    let valid = isError(false, lastValidInput.current)
      ? notEmpty
        ? (React.Children.toArray(children).find(
            (item: React.ReactElement) => item.props.value
          ) as React.ReactElement | undefined)?.props.value || ''
        : ''
      : lastValidInput.current

    setSearch(valid)
    onChange?.(valid)
    onChangeDone?.(valid, error)
  }

  const shouldClose = (event: MouseEvent) => {
    // Close if new target is outside of List
    if (
      !input.current?.contains(event.target as Element) &&
      !list.current?.contains(event.target as Element)
    ) {
      setRenderedOpen(false)
      callbackDone(search)
    }
  }

  const classes = useStyles()

  const input = React.useRef() as React.RefObject<HTMLInputElement>
  const list = React.useRef() as React.RefObject<HTMLUListElement>

  const [search, setSearch] = React.useState('')
  const [filteredSearch, setFilteredSearch] = React.useState(
    null as React.ReactNode
  )
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(
    errorAtStartup ? isError(open) : false
  )
  const [renderedHeight, setRenderedHeight] = React.useState(maxHeight)

  const [renderedOpen, setRenderedOpen] = React.useState(open)

  const lastValidInput = React.useRef('')
  const fadeOutRenderedSearch = React.useRef(null as React.ReactNode)

  React.useEffect(() => {
    if (value !== undefined) setSearch(value as string)
  }, [value])

  React.useEffect(() => {
    if (overwriteSearchOnError !== undefined && isError(false, search))
      overwriteSearchValid()
  }, [overwriteSearchOnError])

  React.useEffect(() => {
    let containContent = false

    let filtered = React.Children.toArray(children).filter(
      (item: React.ReactElement) => {
        if (item.props.value !== undefined) {
          let filtered =
            item.props.value.toLowerCase().includes(search.toLowerCase()) &&
            item.props.value !== search

          if (filtered) containContent = true

          return filtered
        } else return true
      }
    )

    setFilteredSearch(containContent ? filtered : children)
  }, [search, children])

  React.useEffect(() => {
    if (!isError(false, search)) lastValidInput.current = search
  }, [search])

  React.useEffect(() => {
    setError(isError(open))
  }, [search, open, allowCustomInputs, errorOnEmpty, errorWhileFocused])

  React.useEffect(() => {
    if (open) {
      window.addEventListener('resize', recalculateRenderedHeight)
      window.addEventListener('scroll', recalculateRenderedHeight)
    }

    return () => {
      window.removeEventListener('resize', recalculateRenderedHeight)
      window.removeEventListener('scroll', recalculateRenderedHeight)
    }
  }, [open, maxHeight])

  // Feed new search into event callback by reapplying event listeners
  React.useEffect(() => {
    if (open) {
      document.addEventListener('click', shouldClose)
      document.addEventListener('focus', shouldClose, true)
    }

    return () => {
      document.removeEventListener('click', shouldClose)
      document.removeEventListener('focus', shouldClose, true)
    }
  }, [open, search])

  React.useEffect(() => {
    if (renderedOpen) return setOpen(true)
    else {
      let timeout = setTimeout(() => setOpen(false), 250)

      return () => clearTimeout(timeout)
    }
  }, [renderedOpen])

  React.useLayoutEffect(() => {
    if (open) recalculateRenderedHeight()
  }, [open])

  React.useEffect(() => {
    let initialSearch =
      allowCustomInputs ||
      React.Children.toArray(children).some(
        (item: React.ReactElement) => item.props.value === props.defaultValue
      )
        ? defaultValue
        : notEmpty
        ? (React.Children.toArray(children).find(
            (item: React.ReactElement) => item.props.value
          ) as React.ReactElement | undefined)?.props.value || ''
        : ''

    if (initialSearch) {
      setSearch(initialSearch)
      callbackDone(initialSearch)
    }
  }, [])

  React.useEffect(() => {
    if (!props.errorAtStartup) setError(false)
  }, [])

  return (
    <div style={{ ...style, display: 'inline-box' }} className={className}>
      <TextField
        ref={input}
        value={search}
        error={error}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(event.target.value)
          onChange?.(event.target.value)
        }}
        onFocus={() => setRenderedOpen(true)}
        style={{ width: '100%', height: '100%', display: 'inline-grid' }}
        {...rest}
      />
      <Fade
        in={renderedOpen}
        unmountOnExit
        onEnter={() => (fadeOutRenderedSearch.current = filteredSearch)} // Saefty set in case search did not changed but filteredSearch did
        onExit={() => (fadeOutRenderedSearch.current = filteredSearch)} // Replaces fadeOutRenderedSearch before filteredSearch gets updated by setSearch inside List onClick
      >
        <List
          style={{
            minWidth: (input.current?.getBoundingClientRect() as DOMRect)
              ?.width,
            maxHeight: renderedHeight,
            maxWidth:
              maxWidth === 'none'
                ? (input.current?.getBoundingClientRect() as DOMRect)?.width
                : maxWidth,
            height: 'initial',
            zIndex: renderedOpen ? 101 : 100,
            borderTopRightRadius:
              (list.current?.getBoundingClientRect() as DOMRect)?.width >
              (input.current?.getBoundingClientRect() as DOMRect)?.width
                ? 3
                : 0
          }}
          className={classes.root}
          onClick={(event) => {
            let value = (event.target as Element)
              .getAttribute('value')
              ?.toString()
            if (value) {
              setRenderedOpen(false)
              callbackDone(value)
              setSearch(value as string)
            }
          }}
          ref={list}
        >
          {renderedOpen ? filteredSearch : fadeOutRenderedSearch.current}
        </List>
      </Fade>
    </div>
  )
}
